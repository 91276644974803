import React from "react";
import { Routes, Route } from "react-router-dom";
import Login from "./components/Login";
import AppSkeleton from "./components/AppSkeleton";
import Layout from "./components/Layout";
import Admin from "./components/Admin";
import Missing from "./components/Missing";
import Unauthorized from "./components/Unauthorized";
import RequireAuth from "./components/RequireAuth";
import PersistLogin from "./components/PersistLogin";
import ForgotPassword from "./components/ForgotPassword";
import EmailVerification from "./components/EmailVerification";
import Home from "./components/Home";
import EmailCenter from "./components/EmailCenter";
import SettingsCenter from "./components/SettingsCenter";
import OAuthRedirect from "./components/OAuthRedirect";

const ROLES = {
  User: "user",
  Admin: "admin",
};

function App() {
  return (
    <Routes>
      {/* The Layout component (within which all descendant components render, via an Outlet)
        can be used to apply styles across the app. */}
      <Route path="/" element={<Layout />}>
        {/* public routes */}
        <Route path="login" element={<Login />} />
        <Route path="unauthorized" element={<Unauthorized />} />
        <Route path="emailVerification" element={<EmailVerification />} />
        <Route path="forgotPassword" element={<ForgotPassword />} />

        {/* we want to protect these routes */}
        <Route element={<PersistLogin />}>
          <Route
            element={<RequireAuth allowedRoles={[ROLES.User, ROLES.Admin]} />}
          >
            <Route path="/" element={<AppSkeleton />}>
              <Route path="/" element={<Home />} />
              <Route path="/email" element={<EmailCenter />} />
              <Route path="/settings" element={<SettingsCenter />} />
            </Route>

            <Route
              path="quickbooks_oauth_redirect"
              element={<OAuthRedirect />}
            />
          </Route>

          <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
            <Route path="admin" element={<Admin />} />
          </Route>
        </Route>

        {/* catch all */}
        <Route path="*" element={<Missing />} />
      </Route>
    </Routes>
  );
}

export default App;
