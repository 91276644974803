import { Link } from "react-router-dom";
import React from "react";
import { signOut } from "firebase/auth";
import { firebaseAuth } from "../firebase-config";
import { Group, Text } from "@mantine/core";

const EmailVerification = () => {
  // Sign out so that we get a new user token when the user next connects - otherwise the old token will
  // be reused, and the server will prevent his login even if he verified his email (until the token naturally expires).
  signOut(firebaseAuth);
  return (
    <section>
      <Group ta="center" ml={8}>
        {" "}
        Verify your email address, and then
        <Text td="underline">
          <Link to={"/"}>return to the login page</Link>
        </Text>
      </Group>
    </section>
  );
};

export default EmailVerification;
