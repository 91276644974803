import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./store/store";
import App from "./App";
import "./index.css";
// import '@mantine/dropzone/styles.css';
import { BrowserRouter } from "react-router-dom";
import { createTheme, MantineProvider } from "@mantine/core";
import "@mantine/tiptap/styles.css";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

// Scroll to top of new page automatically, every time the page changes.
// Taken from here:  https://stackoverflow.com/a/61602724
function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
}

const theme = createTheme({
  colors: {
    purple: [
      "#f1f1ff",
      "#e0e0f3",
      "#bebede",
      "#9a9aca",
      "#7b7bb8",
      "#6868ae",
      "#5e5eaa",
      "#4e4e96",
      "#444587",
      "#393b79",
    ],
  },
  fontFamily: "Poppins, sans-serif",
  primaryColor: "purple",
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <Provider store={store}>
      <MantineProvider theme={theme}>
        <BrowserRouter>
          <ScrollToTop />
          <App />
        </BrowserRouter>
      </MantineProvider>
    </Provider>
  </React.StrictMode>,
);
