export const displayISODate = (isoDate: string): string => {
  return isoDate.split("T")[0];
};

export const displayCurrencyAmount = (
  amount: number,
  currency: string,
): string => {
  let currencyDisplay = currency;
  if (currency === "ILS" || currency === "NIS") {
    currencyDisplay = "₪";
  } else if (currency === "USD") {
    currencyDisplay = "$";
  }
  const amountDisplay = amount.toLocaleString(undefined, {
    maximumFractionDigits: 1,
  });
  if (currencyDisplay === currency) {
    return `${amountDisplay} ${currencyDisplay}`;
  }
  return `${currencyDisplay}${amountDisplay}`;
};
