import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { RootState } from "../store"; // Import RootState from your store configuration

export interface Payment {
  id: number;
  external_id?: string;
  payment_type?: string;
  amount?: number;
  amountUSD?: number;
  customer_organization_name: string;
  currency_type?: string;
  due_date?: string;
  daysUntilDueDate?: number;
  date_refreshed?: string;
  date_paid_or_closed?: string;
  payment_status?: string;
  intention_status?: string;
  budy_organization_id?: number;
  customer_organization_id?: number;
  last_reminder?: string;
  next_reminder?: string;
  time_last_received_message?: string;
  daysSinceLastReceived?: number;
  message_draft_url?: string;
  correspondence_url?: string;
}

const paymentAdapter = createEntityAdapter({
  selectId: (payment: Payment) => payment.id,
  sortComparer: (a, b) => {
    // 1. Sort by daysUntilDueDate: higher values should appear earlier.
    if (a.daysUntilDueDate !== b.daysUntilDueDate) {
      return b.daysUntilDueDate! - a.daysUntilDueDate!;
    }

    // 2. If daysUntilDueDate are equal, sort by message_draft_url: non-empty strings appear earlier.
    const aHasMessageDraft = !!a.message_draft_url;
    const bHasMessageDraft = !!b.message_draft_url;
    if (aHasMessageDraft !== bHasMessageDraft) {
      return bHasMessageDraft ? 1 : -1;
    }

    // 3. Finally, if all else is equal, sort by external_id.
    return (a.external_id || "").localeCompare(b.external_id || "");
  },
});

const initialState = paymentAdapter.getInitialState();

const paymentsSlice = createSlice({
  name: "payments",
  initialState,
  reducers: {
    updatePayment: paymentAdapter.updateOne,
    addPayments: paymentAdapter.addMany,
    setPayments: paymentAdapter.setAll,
  },
});

export const { addPayments, setPayments, updatePayment } =
  paymentsSlice.actions;
export default paymentsSlice.reducer;

export const { selectAll: selectAllPayments, selectById: selectPaymentById } =
  paymentAdapter.getSelectors((state: RootState) => state.payments);
