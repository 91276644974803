import { useEffect, useMemo, useRef, useState } from "react";
import {
  MRT_ColumnFiltersState,
  MRT_DensityState,
  MRT_PaginationState,
  MRT_SortingState,
  MRT_TableInstance,
  MRT_TableOptions,
  MRT_TableState,
  MRT_VisibilityState,
  useMantineReactTable,
  type MRT_ColumnDef,
} from "mantine-react-table";
import { Payment } from "../store/slices/paymentSlice";
import { useMantineTheme, ThemeIcon } from "@mantine/core";
import {
  IconAlertCircle,
  IconAlertCircleFilled,
  IconCheck,
  IconClockQuestion,
  IconHandFinger,
  IconPencil,
} from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import { MagnifyingGlass } from "@phosphor-icons/react";
import { displayCurrencyAmount, displayISODate } from "../utils/display";

const getIntentionStatusDisplayName = (status: string) => {
  if (status === "Approved") {
    return "Approved";
  }
  if (status === "Unresolved") {
    return "Unresolved";
  }
  if (status === "No_reply") {
    return "No Reply";
  }
  return "Needs Attention";
};

const useInvoicesTable = (payments: Payment[]): MRT_TableInstance<Payment> => {
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const icon = (
    <MagnifyingGlass
      weight="light"
      size={20}
      className="text-lavender-900/60"
    />
  );
  const columns = useMemo<MRT_ColumnDef<Payment>[]>(
    () => [
      {
        accessorKey: "intention_status",
        filterVariant: "select",
        mantineFilterSelectProps: {
          data: ["Approved", "Unresolved", "No Reply", "Needs Attention"],
        },
        filterFn: (row, id, filterValue) => {
          const cellValue = row.getValue<string>(id);
          const cellDisplayName = getIntentionStatusDisplayName(cellValue);
          return cellDisplayName === filterValue;
        },
        header: "Status",
        Cell: ({ renderedCellValue }) => {
          let color = "orange";
          let icon = <IconClockQuestion />;
          if (renderedCellValue === "Unresolved") {
            color = "yellow";
            icon = <IconHandFinger />;
          } else if (renderedCellValue === "Approved") {
            color = "green";
            icon = <IconCheck />;
          } else if (renderedCellValue === "Needs_attention") {
            color = "red";
            icon = <IconAlertCircle />;
          }
          return (
            <ThemeIcon color={color} variant="transparent">
              {icon}
            </ThemeIcon>
          );
        },
      },
      {
        accessorKey: "external_id",
        header: "Invoice #",
      },
      {
        accessorKey: "customer_organization_name",
        header: "Client",
        Cell: ({ cell }) => cell.getValue<string>().slice(0, 60),
      },
      {
        accessorKey: "daysUntilDueDate",
        header: "Overdue?",
        Cell: ({ cell }) =>
          cell.getValue<number>() > 1 ? (
            <ThemeIcon color="red" variant="transparent">
              <IconAlertCircleFilled />
            </ThemeIcon>
          ) : (
            <></>
          ),
      },
      {
        accessorKey: "message_draft_url",
        header: "Email draft",
        Cell: ({ renderedCellValue }) =>
          renderedCellValue && renderedCellValue != "" ? (
            <ThemeIcon color="black" variant="transparent">
              <IconPencil />
            </ThemeIcon>
          ) : (
            <></>
          ),
      },
      {
        accessorFn: (row) =>
          displayCurrencyAmount(row.amount, row.currency_type),
        header: "Amount",
      },
      {
        accessorKey: "due_date",
        header: "Due date",
        Cell: ({ cell }) => displayISODate(cell.getValue<string>()),
      },
      {
        accessorKey: "daysSinceLastReceived",
        header: "Days since their last email",
        size: 150,
        Cell: ({ renderedCellValue }) =>
          renderedCellValue == Infinity ? "-" : renderedCellValue,
      },
    ],
    [],
  );

  //pass table options to useMantineReactTable
  const table = useMantineReactTable({
    columns,
    data: payments, // must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    initialState: {
      density: "xs",
      showColumnFilters: true,
      showGlobalFilter: true,
      pagination: {
        pageIndex: 0,
        pageSize: 12,
      },
    },
    enableGlobalFilter: true,
    positionGlobalFilter: "left",
    enableColumnActions: false,
    enableFullScreenToggle: false,
    enableColumnFilters: false,
    enableBottomToolbar: false,
    paginationDisplayMode: "pages",
    positionPagination: "none",
    enableFilterMatchHighlighting: false,
    defaultColumn: { minSize: 40, maxSize: 1000, size: 10 },
    enableDensityToggle: false,
    enableTopToolbar: false,
    mantineTableHeadProps: {
      className: "text-midnight-900 font-medium text-xs",
    },
    mantineTableHeadCellProps: {
      style: {
        backgroundColor: theme.colors.purple[1],
        verticalAlign: "middle",
      },
    },
    mantineSearchTextInputProps: {
      variant: "unstyled",
      placeholder: "search",
      rightSection: null,
      leftSection: icon,
      classNames: {
        wrapper: "w-full",
        input:
          "placeholder:text-lavender-900/60 text-lavender-600 caret-lavender-600",
        root: "flex border rounded-lg text-sm w-auto focus-within:shadow-md hover:shadow-md transition transform border-lavender-300 bg-lavender-50 pr-2",
      },
    },
    mantineTableBodyProps: {
      className: "text-midnight-700 font-normal text-xs",
    },
    mantinePaginationProps: {
      showRowsPerPage: false,
      withEdges: false,
      classNames: {
        // negative ml to remove the margin added by MRT
        root: "mt-2 -ml-4",
        control:
          "data-[active]:bg-lavender-100 rounded-full text-midnight-900 text-sm disabled:bg-neutral-50 disabled:text-neutral-400 transition transform",
      },
    },
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: () => {
        navigate("/email", { state: row.original });
      },
      style: {
        cursor: "pointer",
      },
    }),
    mantinePaperProps: {
      style: { "--mrt-row-hover-background-color": theme.colors.purple[0] },
    },
  });

  return table;
};

export default useInvoicesTable;
