import { Icon, SquaresFour } from "@phosphor-icons/react";
import { UnstyledButton, Text } from "@mantine/core";

export interface NavButtonProps {
  /** Is this the currently selected page */
  selected?: boolean;
  /**  Button label text */
  label: string;
  /**  Icon to use in the button */
  Icon?: Icon;
  key: number;
  id: number;
  /**  Function to run on click */
  onClick?: () => void;
}

/**  Button for use in the application navbar */
export const NavButton = ({
  selected = false,
  Icon = SquaresFour,
  label,
  id,
  onClick,
}: NavButtonProps) => {
  return (
    <div key={id} className="flex flex-col items-center p-2 rounded-lg">
      <div className="p-2 hover:bg-neutral-100 rounded-lg transition transform">
        <UnstyledButton
          onClick={onClick}
          className={[
            "p-2 shadow-md rounded-lg",
            selected ? "bg-midnight-900" : "bg-lavender-50",
          ].join(" ")}
        >
          <Icon
            weight="light"
            size={32}
            className={
              selected ? "text-white" : "text-midnight-900" // + " w-full h-full"
            }
          />
        </UnstyledButton>
      </div>
      <Text className="text-xs text-midnight-900 font-medium text-center">
        {label}
      </Text>
    </div>
  );
};
