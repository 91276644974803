import { useNavigate, Link, useLocation } from "react-router-dom";
import { setCredentials } from "../store/slices/authSlice";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { useForm, matchesField } from "@mantine/form";
import { IconLock, IconAt } from "@tabler/icons-react";
import {
  TextInput,
  PasswordInput,
  Group,
  Checkbox,
  Button,
  Paper,
  Text,
  LoadingOverlay,
  Anchor,
  Center,
  Container,
} from "@mantine/core";
import { firebaseAuth } from "../firebase-config";
import { sendPasswordResetEmail } from "firebase/auth";

export default function ForgotPassword() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [serverResponse, setServerResponse] = useState<string | null>(null);
  const dispatch = useDispatch();

  const form = useForm({
    initialValues: {
      email: "",
    },
  });

  const handleSubmit = async (values) => {
    setLoading(true);
    setError(null);

    try {
      sendPasswordResetEmail(firebaseAuth, values.email)
        .then(() => {
          setServerResponse(
            "If that email exists, you will receive a link with instructions to reset your password. You can close this tab now and check your email.",
          );
          setLoading(false);
        })
        .catch((error) => {
          setError(error.message);
          setLoading(false);
        });
    } catch (err) {
      setError("Server is down. Try again later.");
      setLoading(false);
    }
  };

  return (
    <Container w={330} mt={15}>
      <Paper
        style={{
          position: "relative",
          // backgroundColor: 'var(--mantine-color-gray-very-light)',
          backgroundColor: "transparent",
        }}
        withBorder
        p={10}
      >
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <LoadingOverlay visible={loading} />

          <Text fw={700} ta="center">
            Enter the email you signed up with
          </Text>

          <TextInput
            mt="md"
            required
            placeholder="Your email"
            label="Email"
            leftSection={<IconAt size={16} stroke={1.5} />}
            {...form.getInputProps("email")}
          />

          {error && (
            <Text c="red" size="sm" mt="sm">
              {error}
            </Text>
          )}

          {serverResponse && (
            <Text size="sm" mt="sm">
              {serverResponse}
            </Text>
          )}

          <Group justify="space-between" mt="sm">
            <Button color="blue" type="submit">
              Send
            </Button>
          </Group>
          <Text ta="center" td="underline" size="sm" mt={8}>
            <Link to={"/"}>Back to login</Link>
          </Text>
        </form>
      </Paper>
    </Container>
  );
}
