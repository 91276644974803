import { useGetOrganizationQuery } from "../store/slices/apiSliceEndpoints";
import { Card, Center } from "@mantine/core";
import { Text, Button, Loader } from "@mantine/core";

function AccountingSoftwareSettings() {
  const { data: organization, isLoading } = useGetOrganizationQuery();
  const installQuickBooks = () => {
    window.location.href = organization.quickbooks_authorization_url;
  };

  if (isLoading) {
    return <Loader />;
  }

  return organization.accounting_software ? (
    <div>{organization.accounting_software} - Connected ✅</div>
  ) : (
    <Button
      fullWidth
      className="mt-6 mb-2"
      radius="xl"
      onClick={installQuickBooks}
    >
      Connect to QuickBooks
    </Button>
  );
}

export default function SettingsCenter() {
  return (
    <div className="flex flex-col">
      <Text className="text-midnight-900 font-medium text-xl">Settings</Text>
      <div className="flex flex-row justify-center w-full">
        <Card
          shadow="sm"
          radius="md"
          px="lg"
          py="lg"
          mt="xl"
          withBorder
          style={{ minWidth: 350 }}
        >
          <Text className="text-lg">Accounting Software</Text>
          <Center px="xl" pt="xl">
            <AccountingSoftwareSettings />
          </Center>
        </Card>
      </div>
    </div>
  );
}
