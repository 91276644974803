import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { useLinkAccountingSoftwareMutation } from "../store/slices/apiSliceEndpoints";
import PageLoading from "./PageLoading";

const OAuthRedirect = () => {
  const navigate = useNavigate();
  const [linkAccountingSoftware] = useLinkAccountingSoftwareMutation();
  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");
  const state = searchParams.get("state");
  const realmId = searchParams.get("realmId");
  const error = searchParams.get("error");

  useEffect(() => {
    if (error) {
      console.error("Error linking accounting software", error);
      navigate("/settings");
      return;
    }
    const linkAccountingSoftwarePromise = linkAccountingSoftware({
      code,
      state,
      realmId,
      name: "QuickBooks",
    });
    const linkAndRedirect = async () => {
      try {
        await linkAccountingSoftwarePromise.unwrap();
      } catch (error) {
        console.error("Error linking accounting software", error);
      }
      navigate("/settings");
    };
    linkAndRedirect();
    // P.S. Due to StrictMode, this component will unmount and remount.
    return () => {
      linkAccountingSoftwarePromise.abort();
    };
  }, [code, error, linkAccountingSoftware, navigate, realmId, state]);

  return <PageLoading />;
};

export default OAuthRedirect;
