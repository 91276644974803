import { useDisclosure } from "@mantine/hooks";
import { useLocation, useNavigate } from "react-router-dom";
import {
  selectUnpaidInvoices,
  selectOpenBalance,
  selectOverdue,
  selectApprovalsAmountAndCount,
  selectUnresolvedAmountAndCount,
  selectNoReplyAmountAndCount,
  selectNeedsAttentionAmountAndCount,
} from "../store/slices/generalDataSlice";
import {
  ActionIcon,
  Button,
  Center,
  Menu,
  Loader,
  Modal,
  ThemeIcon,
  Dialog,
  Group,
} from "@mantine/core";
import { useState, useMemo, useEffect } from "react";
import { Text } from "@mantine/core";
import { TextInput } from "./TextInput";
import { DatePicker } from "./DatePicker";
import { MultiSelect } from "./MultiSelect";
import { Card } from "./Card";
import { MinimalCardList } from "./MinimalCard";
import {
  MagnifyingGlass,
  Receipt,
  Money,
  Clock,
  Alarm,
  SealCheck,
  Envelope,
  Question,
  WarningCircle,
} from "@phosphor-icons/react";
import {
  useGetOrganizationQuery,
  useGetAllOpenPaymentsQuery,
  useGetEmailDraftForAllOpenPaymentsQuery,
} from "../store/slices/apiSliceEndpoints";
import {
  IconAlertCircle,
  IconCheck,
  IconClockQuestion,
  IconHandFinger,
  IconMail,
  IconRotateClockwise,
  IconSend,
} from "@tabler/icons-react";
import { RootState } from "../store/store";
import { selectAllPayments } from "../store/slices/paymentSlice";
import { useDispatch, useSelector } from "react-redux";
import InvoicesTable from "./InvoicesTable";
import { MRT_GlobalFilterTextInput } from "mantine-react-table";
import useInvoicesTable from "../hooks/useInvoicesTable";

const minimalCardData = [
  {
    heading: "Unpaid Invoices",
    description: "?",
    Icon: Receipt,
    key: 1,
  },
  {
    heading: "Open Balance",
    description: "?",
    Icon: Money,
    key: 2,
  },
  {
    heading: "Overdue",
    description: "?",
    Icon: Alarm,
    key: 3,
  },
  // {
  //   heading: "Avg. Invoice to Cash",
  //   description: "?",
  //   Icon: Clock,
  //   key:4,
  // },
];

const cardData = [
  {
    heading: "?",
    description: "Approvals",
    subText: "",
    color: "lime",
    Icon: IconCheck,
    id: 1,
    filterValue: "Approved",
  },
  {
    heading: "?",
    description: "Unresolved",
    subText: "",
    color: "yellow",
    Icon: IconHandFinger,
    id: 2,
    filterValue: "Unresolved",
  },
  {
    heading: "?",
    description: "No Reply",
    subText: "",
    color: "apricot",
    Icon: IconClockQuestion,
    id: 3,
    filterValue: "No Reply",
  },
  {
    heading: "?",
    description: "Needs Attention",
    subText: "",
    color: "red",
    Icon: IconAlertCircle,
    id: 4,
    filterValue: "Needs Attention",
  },
];

const Home = () => {
  const { isLoading: isOrganizationLoading, data: organization } =
    useGetOrganizationQuery();
  const { isLoading: isAllOpenPaymentsLoading } = useGetAllOpenPaymentsQuery();
  const { isLoading: isEmailDraftForAllOpenPaymentsLoading } =
    useGetEmailDraftForAllOpenPaymentsQuery();
  const allPayments = useSelector((state: RootState) =>
    selectAllPayments(state),
  );
  const isLoading =
    isEmailDraftForAllOpenPaymentsLoading ||
    isAllOpenPaymentsLoading ||
    isOrganizationLoading;
  const unpaidInvoices = useSelector(selectUnpaidInvoices);
  const openBalance = useSelector(selectOpenBalance);
  const overdue = useSelector(selectOverdue);
  const approvalsAmountAndCount = useSelector(selectApprovalsAmountAndCount);
  const unresolvedAmountAndCount = useSelector(selectUnresolvedAmountAndCount);
  const noReplyAmountAndCount = useSelector(selectNoReplyAmountAndCount);
  const needsAttentionAmountAndCount = useSelector(
    selectNeedsAttentionAmountAndCount,
  );
  const [selectedIntentionCardId, setSelectedIntentionCardId] = useState(null);
  const [searchText, setSearchText] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const [
    openedEmailNotifier,
    { toggle: openEmailNotifier, close: closeEmailNotifier },
  ] = useDisclosure(true);
  const invoicesTable = useInvoicesTable(allPayments);

  const emailStatus = location.state?.emailStatus;
  // useEffect(() => {
  //   if (emailStatus == 'email sent successfully') {
  //     // Clear the state after reading it
  //     navigate(location.pathname, { replace: true, state: {} });
  //   }
  // }, [emailStatus, location.pathname, navigate]);

  if (unpaidInvoices) {
    minimalCardData[0].description = unpaidInvoices.toString();
  }
  if (openBalance) {
    minimalCardData[1].description = openBalance.toString();
  }
  if (overdue) {
    minimalCardData[2].description = overdue.toString();
  }
  if (approvalsAmountAndCount) {
    cardData[0].heading = approvalsAmountAndCount[0];
    cardData[0].subText = approvalsAmountAndCount[1];
  }
  if (unresolvedAmountAndCount) {
    cardData[1].heading = unresolvedAmountAndCount[0];
    cardData[1].subText = unresolvedAmountAndCount[1];
  }
  if (noReplyAmountAndCount) {
    cardData[2].heading = noReplyAmountAndCount[0];
    cardData[2].subText = noReplyAmountAndCount[1];
  }
  if (needsAttentionAmountAndCount) {
    cardData[3].heading = needsAttentionAmountAndCount[0];
    cardData[3].subText = needsAttentionAmountAndCount[1];
  }

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const formatLastUpdated = (lastUpdateISOString: string) => {
    const lastUpdateDate = new Date(lastUpdateISOString);
    return `${lastUpdateDate.toDateString()} at ${lastUpdateDate.toLocaleTimeString()}`;
  };

  // Take the received payment data, process the relevant columns, ignore the ones that aren't displayed.
  const processedInvoices = allPayments.map((obj) => {
    const newObj = {};
    const wantedKeys = [
      "external_id",
      "customer_organization_name",
      "amount",
      "due_date",
      "currency_type",
      "intention_status",
      "new_msg",
      "daysUntilDueDate",
      "daysSinceLastReceived",
    ];
    for (const key in obj) {
      if (wantedKeys.includes(key)) {
        if (key == "amount") {
          newObj[key] = obj[key].toLocaleString(undefined, {
            maximumFractionDigits: 1,
          });
        } else if (key == "due_date" && obj[key]) {
          newObj[key] = obj[key].split("T")[0];
        } else {
          newObj[key] = obj[key];
        }
      } else {
        newObj[key] = obj[key];
      }
    }
    return newObj;
  });

  return isLoading ? (
    <Center className="h-full pb-24">
      <Loader />
    </Center>
  ) : (
    <div className="flex flex-col gap-8">
      <div className="flex flex-row items-center w-full gap-4">
        <div className="flex flex-row">
          <MRT_GlobalFilterTextInput table={invoicesTable} />
        </div>
        <Text className="text-m ml-9" c="gray">
          Last updated: {formatLastUpdated(organization.last_e2e_refresh_end)}
        </Text>
      </div>
      {emailStatus == "email sent successfully" && (
        <Dialog
          opened={openedEmailNotifier}
          withCloseButton
          onClose={closeEmailNotifier}
          size="lg"
          radius="md"
        >
          <Button
            variant="white"
            radius="md"
            mx="20"
            pl="10"
            size={"l"}
            leftSection={<IconSend />}
          >
            Email sent successfully
          </Button>
        </Dialog>
      )}
      <div className="flex flex-col items-start justify-start gap-4 w-full">
        <Text className="text-midnight-900 font-medium text-xl">
          Billing Status
        </Text>
        <MinimalCardList cardProps={minimalCardData} />
      </div>
      <div className="flex flex-col items-start justify-start gap-4 w-full">
        <Text className="text-midnight-900 font-medium text-xl">
          Email Status
        </Text>
        <div className="flex flex-row items-center justify-between w-full">
          {cardData.map((data) => (
            <Card
              key={data.id}
              heading={data.heading}
              description={data.description}
              subText={data.subText}
              Icon={data.Icon}
              color={
                data.color as
                  | "lime"
                  | "yellow"
                  | "apricot"
                  | "aqua"
                  | "lavender"
                  | "orange"
                  | "red"
              }
              onClickFunc={() => {
                if (selectedIntentionCardId == data.id) {
                  setSelectedIntentionCardId(null);
                  invoicesTable.setColumnFilters((prev) =>
                    prev.filter((filter) => filter.id !== "intention_status"),
                  );
                } else {
                  setSelectedIntentionCardId(data.id);
                  invoicesTable.setColumnFilters((prev) => [
                    ...prev,
                    { id: "intention_status", value: data.filterValue },
                  ]);
                }
              }}
              isSelected={selectedIntentionCardId == data.id}
            />
          ))}
        </div>
      </div>
      <div className="flex flex-col items-start justify-start gap-2 w-full">
        <Text className="text-midnight-900 font-medium text-xl mb-2">
          Unpaid Invoices
        </Text>
        <div className="w-full">
          <InvoicesTable table={invoicesTable} />
        </div>
      </div>
    </div>
  );
};

export default Home;
