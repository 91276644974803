import { Outlet, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { selectCurrentToken, setCredentials } from "../store/slices/authSlice";
import { useDispatch, useSelector } from "react-redux";
import React from "react";
import { onAuthStateChanged, sendEmailVerification } from "firebase/auth";
import { firebaseAuth } from "../firebase-config";
import PageLoading from "./PageLoading";

const PersistLogin = () => {
  const [isPageLoading, setIsPageLoading] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    // TODO: This should be removed. The roles should be determined by the server, not set locally.
    const roles = ["user"];
    onAuthStateChanged(firebaseAuth, async (loggedInuser) => {
      if (loggedInuser) {
        const uid = loggedInuser.uid;
        const user = loggedInuser.displayName;
        console.log("verified", loggedInuser.emailVerified, uid);
        if (!loggedInuser.emailVerified) {
          await sendEmailVerification(loggedInuser)
            .then(() => {
              navigate("/emailVerification");
            })
            .catch((error) => {
              navigate("/emailVerification");
            });
        } else {
          // since we aren't passing `true` as an argument to getIdToken, it won't request that the token be refreshed.
          const token = await loggedInuser.getIdToken();
          console.log(uid, "persist page login");
          dispatch(setCredentials({ user, roles, token, uid }));
        }
      } else {
        console.log("user isn't logged in");
      }
      setIsPageLoading(false);
    });
  }, [dispatch, navigate]);

  return <>{isPageLoading ? <PageLoading /> : <Outlet />}</>;
};

export default PersistLogin;
