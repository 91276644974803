import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAMdVoxqZBiD5_aSbGl9WVRX7eRwA7mKa8",
  authDomain: "mashehu-e62d7.firebaseapp.com",
  projectId: "mashehu-e62d7",
  storageBucket: "mashehu-e62d7.appspot.com",
  messagingSenderId: "414937388523",
  appId: "1:414937388523:web:f209b3b5efaf36aaabf5ae",
  measurementId: "G-5SVD5FGCE0",
};

const firebaseApp = initializeApp(firebaseConfig);
// const analytics = getAnalytics(firebaseApp);
export const firebaseAuth = getAuth(firebaseApp);
