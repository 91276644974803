import { RootState } from "../store/store";
import {
  useGetCorrespondenceForPaymentQuery,
  useSendEmailMutation,
} from "../store/slices/apiSliceEndpoints";
import {
  FileButton,
  Group,
  VisuallyHidden,
  Notification,
  rem,
  Loader,
  Center,
} from "@mantine/core";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import React from "react";
import EmailEditor from "./EmailEditor";
import { useLocation } from "react-router-dom";
import {
  Text,
  TextInput,
  FileInput,
  Button,
  Timeline,
  Spoiler,
  Divider,
} from "@mantine/core";
import { useState } from "react";
import {
  CalendarDots,
  Invoice,
  HandCoins,
  ArrowLeft,
  Spinner,
} from "@phosphor-icons/react";
import { MinimalCardList } from "./MinimalCard";
import {
  IconAdjustments,
  IconBrandWhatsapp,
  IconCalendarClock,
  IconCheck,
  IconGrid3x3,
  IconMailbox,
  IconMessageChatbot,
  IconPhoto,
  IconSend,
  IconUpload,
} from "@tabler/icons-react";
import { selectEmailById } from "../store/slices/emailSlice";
import { selectClientById } from "../store/slices/clientSlice";
import { useSelector } from "react-redux";
import DOMPurify from "dompurify";
import { displayCurrencyAmount, displayISODate } from "../utils/display";
import { Payment } from "../store/slices/paymentSlice";

const minimalCardData = [
  {
    heading: "Payment Due Date",
    description: "",
    Icon: CalendarDots,
    key: 1,
  },
  {
    heading: "Invoice Amount",
    description: "",
    Icon: Invoice,
    key: 2,
  },
  {
    heading: "Invoice ID",
    description: "",
    Icon: IconGrid3x3,
    key: 3,
  },
  // {
  //   heading: "Client's balance",
  //   description: "?",
  //   Icon: HandCoins,
  //   key:4,
  // },
];

function isStartOfTextRtl(text) {
  // Determines whether or not a text is considered RTL (i.e from a language that is written "right to left"), based on
  // the first character in the text.
  if (!text) return false; // Handle empty string
  // TODO: This hasn't been tested for all RTL languages, only for Hebrew so far.
  const rtlCharRegex = /[\u0590-\u08FF\uFB1D-\uFDFD\uFE70-\uFEFC]/;
  return rtlCharRegex.test(text[0]);
}

export default function EmailCenter() {
  const location = useLocation();
  const paymentData = location.state || ({} as Payment[]);
  const emailDraft = useSelector((state: RootState) =>
    selectEmailById(state, paymentData.message_draft_url),
  );
  const [subject, setSubject] = useState(emailDraft?.subject || "");
  const [emailToAddress, setEmailToAddress] = useState(emailDraft?.to || "");
  const [emailCcAddress, setEmailCcAddress] = useState(emailDraft?.cc || "");
  const [emailBccAddress, setEmailBccAddress] = useState(emailDraft?.bcc || "");
  const [attachments, setAttachments] = useState<File[]>([]);
  const [emailDraftModificationPrompt, setEmailDraftModificationPrompt] =
    useState("");
  const [file, setFile] = useState<File | null>(null);
  const [toggleCcBcc, setToggleCcBcc] = useState(false);
  let ccBccVisibility = false;
  const navigate = useNavigate();
  const client = useSelector((state: RootState) =>
    selectClientById(state, paymentData["customer_organization_id"]),
  );
  const [sendEmail, { isLoading, isSuccess, isError }] = useSendEmailMutation();

  const {
    data: correspondenceData,
    isLoading: isLoadingCorrespondence,
    isSuccess: isSuccessCorrespondence,
  } = useGetCorrespondenceForPaymentQuery(paymentData.correspondence_url, {
    skip: !paymentData.correspondence_url, // Avoid making the query if no correspondence_url
  });

  useEffect(() => {
    if (emailDraft) {
      setSubject(emailDraft.subject || "");
      setEmailToAddress(emailDraft.to || "");
      setEmailCcAddress(emailDraft.cc || "");
      setEmailBccAddress(emailDraft.bcc || "");
    }
  }, [emailDraft]);

  const handleSubmit = async (e) => {
    const emailData = {
      text: emailDraft?.text,
      subject: emailDraft?.subject,
      to: emailDraft?.to,
      cc: emailDraft?.cc,
      bcc: emailDraft?.bcc,
      threadId: emailDraft?.thread_id,
      replyMessageId: emailDraft.reply_message_id,
    };
    e.preventDefault();
    try {
      const response = await sendEmail({
        emailData,
        draftUrl: paymentData.message_draft_url,
        attachments,
      }).unwrap();
      if (response == "success") {
        navigate("/", { state: { emailStatus: "email sent successfully" } });
      }
    } catch (error) {
      console.error("Failed to send email", error, emailData);
    }
  };

  if (paymentData) {
    minimalCardData[0].description = displayISODate(paymentData["due_date"]);
    minimalCardData[1].description = displayCurrencyAmount(
      paymentData["amount"],
      paymentData["currency_type"],
    );
    minimalCardData[2].description = paymentData.external_id;
  }

  ccBccVisibility = !!toggleCcBcc;

  const goBack = () => {
    navigate(location.state?.from || "/");
  };
  const hoverClassButton = "hover:bg-midnight-200";
  const checkIcon = <IconCheck style={{ width: rem(20), height: rem(20) }} />;

  return (
    <>
      <div className="flex flex-col mb-4">
        <div className="flex flex-row">
          <div className="w-1/12 mb-1 cursor-pointer" onClick={goBack}>
            <ArrowLeft weight="thin" size={32} />
          </div>
          <div className="w-8/12 mb-1 ml-[10%]">
            <MinimalCardList cardProps={minimalCardData} />
          </div>
        </div>
        {emailDraft && (
          <div>
            <div className="flex flex-row justify-center my-10">
              {isLoading ? (
                <Loader size={20} />
              ) : (
                <Button
                  variant="outline"
                  radius="md"
                  color="#051433"
                  className={hoverClassButton}
                  mx="20"
                  pl="10"
                  size={"l"}
                  leftSection={<IconSend />}
                  onClick={handleSubmit}
                >
                  Send
                </Button>
              )}
              {/* <Button variant="outline" radius="md" color='#051433' className={hoverClassButton} mr='20' pl='10' size={"l"} leftSection={<IconMessageChatbot/>}>Change</Button>  */}
              {/* <Button variant="outline" radius="md" color='#051433' className={hoverClassButton} mr='20' pl='10' size={"l"} leftSection={<IconCalendarClock />}>Follow Up</Button> */}
              {/* <Button variant="outline" radius="md" color='#051433' className={hoverClassButton} pl='10' size={"l"} leftSection={<IconBrandWhatsapp />}>WhatsApp</Button> */}
            </div>

            <div className="mb-5">
              <TextInput
                styles={{ input: { color: "gray" } }}
                value={emailDraftModificationPrompt}
                placeholder="Ask the AI to change the email"
                onChange={(event) =>
                  setEmailDraftModificationPrompt(event.currentTarget.value)
                }
              />
            </div>
            <div className="flex space-x-2 items-end">
              <TextInput
                required
                styles={{ input: { color: "gray" } }}
                value={emailToAddress}
                description="To"
                onChange={(event) =>
                  setEmailToAddress(event.currentTarget.value)
                }
                w={"16rem"}
              />
              <FileInput
                clearable
                multiple
                value={attachments}
                onChange={setAttachments}
                description="Attachments"
                placeholder="Upload here"
              />
              <TextInput
                w={"40rem"}
                styles={{ input: { color: "gray" } }}
                value={subject}
                description="Subject"
                onChange={(event) => setSubject(event.currentTarget.value)}
              />
              <div
                className="cursor-pointer"
                onClick={() => setToggleCcBcc(!toggleCcBcc)}
              >
                <Text size="xs" td="underline">
                  Cc, Bcc
                </Text>
              </div>
            </div>
            {toggleCcBcc && (
              <div className="flex space-x-2 items-end mt-2">
                <TextInput
                  styles={{ input: { color: "gray" } }}
                  value={emailCcAddress}
                  description="Cc"
                  onChange={(event) =>
                    setEmailCcAddress(event.currentTarget.value)
                  }
                  w={"16rem"}
                />
                <TextInput
                  styles={{ input: { color: "gray" } }}
                  value={emailBccAddress}
                  description="Bcc"
                  onChange={(event) =>
                    setEmailBccAddress(event.currentTarget.value)
                  }
                  w={"16rem"}
                />
              </div>
            )}
          </div>
        )}
      </div>

      {emailDraft && <EmailEditor uid={paymentData.message_draft_url} />}

      {/* 
                If there is previous correspondence (in the form of one or more email threads), display each thread separately.
            */}

      {isLoadingCorrespondence && (
        <Center className="mt-5">
          <Loader />
        </Center>
      )}

      {isSuccessCorrespondence && (
        <>
          <Text
            size="lg"
            className="font-bold mt-10 mb-0 w-full underline"
            ta="center"
          >
            Email Correspondence
          </Text>
          <div className="justify-left ml-4 my-2 pb-8">
            {Object.entries(correspondenceData.correspondence).map(
              ([threadKey, threadMessages]) => (
                <React.Fragment key={threadKey}>
                  {/* The key of  `Text` (below) has a random suffix appended to it, to differentiate its key from the `Timeline` element that follows it*/}
                  {Object.keys(correspondenceData.correspondence).length >
                    1 && (
                    <Text
                      size="lg"
                      className="font-bold mb-6 mt-2 w-full"
                      ta="left"
                      key={threadKey + "_"}
                    >
                      Thread # {parseInt(threadKey) + 1}
                    </Text>
                  )}
                  <Timeline bulletSize={30} lineWidth={2} key={threadKey}>
                    {threadMessages.map((msg, index) => {
                      /* 
                            Here we display each separate email within the thread. A potential issue we need to solve, is that some emails (at least, some emails coming from Gmail)
                            don't just include the text that was sent in the email itself, but also additional text that is not particularly interesting, e.g:
                            1. A forwarded email will include the history of the correpondence (which is redundant in our case, as we're displaying the entire thread).
                            2. Various footers and links.

                            Ideally, we'd want to remove all this uninteresting text at the server side, but currently, the filtering is imperfect.
                            As a heuristic, we only display the first three lines of each email, and to view the rest (which will often include potential "uninteresting text"),
                            the user can click a "Show more" button.
                            */
                      // We want to display html within emails so that they remain properly formatted.
                      // So, we sanitize the html before displaying it.
                      const text = DOMPurify.sanitize(msg.content);
                      let numDesiredNewlines = 3;
                      let prevNewLineIndex = -1;
                      let newLineIndex;
                      while (numDesiredNewlines > 0) {
                        numDesiredNewlines -= 1;
                        newLineIndex = text.indexOf("\n", prevNewLineIndex + 1);
                        if (newLineIndex == -1) {
                          newLineIndex = prevNewLineIndex;
                          break;
                        }
                        prevNewLineIndex = newLineIndex;
                      }
                      // firstHalf will include all the text up until the third "newline" character.
                      // secondHalf will include the rest of the text.
                      let firstHalf: string, secondHalf: string | null;

                      if (newLineIndex !== -1) {
                        // Split around the last newline we found (up until the maximum desired number of newlines)
                        firstHalf = text.slice(0, newLineIndex);
                        secondHalf = text.slice(newLineIndex + 1);
                      } else {
                        // If we didn't find any newlines, keep the text as is
                        firstHalf = text;
                        secondHalf = null;
                      }

                      // Determine if the text is written in a right-to-left language (e.g Hebrew, Arabic), and if so, modify the direction of the text.
                      const isRtl = isStartOfTextRtl(text);

                      return (
                        <Timeline.Item
                          bullet={<IconMailbox size={20} />}
                          title={msg.subject}
                          key={index}
                        >
                          {/* Email metadata */}
                          <Text c="dimmed" size="xs" mt={4}>
                            {msg.when}; &nbsp;&nbsp; from: {msg.from_email}
                            ;&nbsp;&nbsp; to: {msg.to_email}
                          </Text>
                          {/* First half of the content */}
                          <Text
                            size="sm"
                            style={{
                              paddingRight: 10,
                              paddingLeft: 10,
                              whiteSpace: "pre-wrap",
                              direction: isRtl ? "rtl" : "ltr",
                              textAlign: isRtl ? "right" : "left",
                            }}
                            dangerouslySetInnerHTML={{
                              __html: firstHalf,
                            }}
                          />

                          {/* Second half of the content (only if it exists) */}
                          {secondHalf && (
                            <Spoiler
                              maxHeight={40}
                              showLabel="Show more"
                              hideLabel="Hide"
                            >
                              <Text
                                size="sm"
                                style={{
                                  paddingRight: 10,
                                  paddingLeft: 10,
                                  whiteSpace: "pre-wrap",
                                  direction: isRtl ? "rtl" : "ltr",
                                  textAlign: isRtl ? "right" : "left",
                                }}
                                dangerouslySetInnerHTML={{
                                  __html: secondHalf,
                                }}
                              />
                            </Spoiler>
                          )}
                        </Timeline.Item>
                      );
                    })}
                  </Timeline>
                </React.Fragment>
              ),
            )}
          </div>
        </>
      )}
    </>
  );
}
