import {
  MantineReactTable,
  MRT_TableInstance,
  MRT_TablePagination,
} from "mantine-react-table";
import { Payment } from "../store/slices/paymentSlice";
interface InvoicesTableProps {
  table: MRT_TableInstance<Payment>;
}

export default function InvoicesTable({ table }: InvoicesTableProps) {
  return (
    <>
      <MantineReactTable table={table} />
      <MRT_TablePagination table={table} />
    </>
  );
}
